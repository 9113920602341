import React from 'react';

const Navbar = () => {
  return (
    <div
      // style={{
      //   height: "10vh",
      //   background: "white",
      //   zIndex: "1000", // Ensures it's above other elements
      //   width: "100%",
      //   position: "fixed", // Fixes the navbar at the top
      //   top: 0, // Aligns it to the top of the page
      //   left: 0, // Aligns it to the left side of the page
      //   paddingLeft: "10%",
      //   paddingTop: "2%",
      //   boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow
      // }}
    >
      Vjthalearning
    </div>
  );
};

export default Navbar;
