import React, { useEffect, useRef, useState } from "react";
import { Table, Input, Button, Modal, Form, Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
import axios from "axios";

const Assessments = () => {
  const [startassessmentText,setStartassessmentText] = useState("Start Assessment")
  const [examPaperUploadText,setExamPaperUploadText] = useState("Upload QNS")
  const [studentDataUploadText,setStdUploadText] = useState("Upload Student Data")
  const [sendRegLinkText,setSendRegLinkText] = useState("Send Registration Link")
  const [studentMailForReg,setStudentRegMail] = useState()
  const [collegeFilter, setCollegeFilter] = useState("");
  const [cgpaFilter, setCgpaFilter] = useState("");
  const [idfilter, setidfilter] = useState("");
  const [deptFilter, setDeptFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [isAssessmentStarted, setIsAssessmentStarted] = useState(false);
  const [assessmentName, setAssessmentName] = useState("Assessment Name");
  const [registrationData, setRegistrationData] = useState([]);
  const [copyBtnText, setCopyBtnText] = useState("Copy Registration url");
  const [isStudentModalVisible, setIsStudentModalVisible] = useState(false);
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [filteredMaleCount, setFilteredMaleCount] = useState(0);
  const [filteredFemaleCount, setFilteredFemaleCount] = useState(0);
  const [mailCount,setMailCount]  = useState(0)
  const [startMailSending,setStartMailSending] = useState(false)
  const { assessmentId } = useParams();

  const [searchStudentId,setStudentId] = useState()
  const handleStudentId = (e)=>{
  setStudentId(e.target.value)
  }
  const resetAttempt = async ()=>{
    const url = `${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/${idfilter}/resettest`
    // const url = `http://localhost:5000/admin/${assessmentId}/${searchStudentId}/resettest`
    await axios.post(url)
    .then(res=>{
      if(res.status === '404'){
        toast.error("Student Id doesn't Exist!",{position:"top-center"})
      }
      toast.success(res.data,{position:"top-center"})
    })
    .catch(err=>toast.error("Student Id doesn't Exist!",{position:"top-center"}))
  }
  const fetchRegistrationData = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/candidates/getregistrations/${assessmentId}`;
      const response = await axios.get(url);
      setRegistrationData(response.data);
      setFilteredData(response.data);
      countGenders(response.data, setMaleCount, setFemaleCount);
    } catch (error) {
      console.error("Error fetching registration data:", error);
      toast.error("Failed to fetch registration data!");
    }
  };

  useEffect(() => {
    fetchRegistrationData();
  }, [assessmentId]);

  const countGenders = (data, setMaleCount, setFemaleCount) => {
    const maleCount = data.filter(item => item.gender === "Male").length;
    const femaleCount = data.filter(item => item.gender === "Female").length;
    setMaleCount(maleCount);
    setFemaleCount(femaleCount);
  };

  const handleCollegeSearch = (e) => {
    const value = e.target.value;
    setCollegeFilter(value);
    applyFilters(value, cgpaFilter, deptFilter);
  };

  const handleCgpaSearch = (e) => {
    const value = e.target.value;
    setCgpaFilter(value);
    applyFilters(collegeFilter, value, deptFilter);
  };

  const handleDeptFilterChange = (value) => {
    setDeptFilter(value);
    applyFilters(collegeFilter, cgpaFilter, value);
  };

  const onChange = (vals)=>{
    setidfilter(vals)
    applyFilters(collegeFilter,cgpaFilter,deptFilter,vals)
  }

  const applyFilters = (college, cgpa, dept , collegeid) => {
    const filtered = registrationData.filter(
      (item) =>
        item.college_name.toLowerCase().includes(college.toLowerCase()) &&
        (cgpa === "" || item.cgpa >= parseFloat(cgpa)) &&
        (dept === "" || item.dept === dept)&&
        (collegeid === "" || item.college_Id === collegeid)

    );
    setFilteredData(filtered);
    countGenders(filtered, setFilteredMaleCount, setFilteredFemaleCount);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Data");
    XLSX.writeFile(workbook, "filtered_data.xlsx");
  };

  const handleUploadFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (file) {
      setExamPaperUploadText("Uploading ....")
      const formData = new FormData();
      formData.append("file", file);
      const url = `${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/uploadquestions`;
      axios
        .post(url, formData)
        .then((response) => {
          setExamPaperUploadText("Upload Successful")
          toast.success("Questions uploaded successfully", {
            position: "top-center",
          });
        })
        .catch((error) => {
          setExamPaperUploadText("Upload Failed")
          toast.error("Failed to upload Questions!", {
            position: "top-center",
          });
        });
    } else {
      toast.error("Please select a file to upload!", {
        position: "top-center",
      });
    }
  };


  const handleStartAssessment = async () => {
    if (file) {
      setStartassessmentText("Sending Mails .....")
      setStartMailSending(true)
      const url = `${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/startassessment`;
      // const url = `http://localhost:5000/admin/${assessmentId}/startassessment`;
    
      for(var i =0;i<filteredData.length;i++)
      {
        const data = {
          email: filteredData[i].email,
        };
        await axios
        .post(url, data)
        .then(() => {
          setMailCount(i+1)
        })
        .catch((err) => {
          toast.error("Some Error Occurred!", {
            position: "top-center",
          });
        });
      }
      setStartassessmentText("Started Successfully")
      toast.success("Assessment started successfully!", {
        position: "top-center",
      });

      setIsAssessmentStarted(true);

      
    } else {
      toast.error("Please select a file to upload!", {
        position: "top-center",
      });
    }
  };

  const handleStudentFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleStudentUpload = () => {
    if (file) {
      setStdUploadText("Adding Student Data ....")
      const formData = new FormData();
      formData.append("file", file);
      const url = `${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/usersregistration`;
      axios
        .post(url, formData)
        .then((response) => {
          fetchRegistrationData();
          setStdUploadText("Added Successfully");
          toast.success("Student data added successfully!", {
            position: "top-center",
          });
        })
        .catch((error) => {
          setStdUploadText("Failed")
          toast.error("Failed to upload student data!", {
            position: "top-center",
          });
        });
    } else {
      toast.error("Please select a file to upload!", {
        position: "top-center",
      });
    }
  };
  const handleSendRegistrationLink = async()=>{
    setSendRegLinkText("Sending...")
    const url = `${process.env.REACT_APP_BASE_URL}/admin/sendnewregmail`
    const data = {
      mail : studentMailForReg,
      reglink : `${process.env.REACT_APP_FRONTEND_URL}/vjthalearning/${assessmentId}`,
    }
    await axios.post(url,data)
    .then(res=>{
      setSendRegLinkText("Reg. Link Sent")
      toast.success("Registration Mail Sent Successfully",{position:"top-center"})
    })
    .catch(err=>toast.error("Mail Not Sent",{position:"top-center"}))
  }
  const copyRegistrationUrl = () => {
    const registrationUrl = `${process.env.REACT_APP_FRONTEND_URL}/vjtha-drive2025/${assessmentId}`;
    navigator.clipboard.writeText(registrationUrl);
    setCopyBtnText("Copied");
  };

  const uniqueDepts = [...new Set(registrationData.map((item) => item.dept))];

  const styles = {
    container: { padding: "20px" },
    heading: { marginBottom: "20px" },
    subHeading: { marginBottom: "20px" },
    filtersContainer: { marginBottom: "20px" },
    filterInput: { marginBottom: "10px" },
    buttonsContainer: { marginBottom: "20px" },
    table: { marginBottom: "20px" },
    button: { marginLeft: "10px" },
    selectBox: { marginBottom: "20px", width: "100%" },
    inputs: { marginRight:"20px", width: "20vw",padding:"8px",border:"1px solid grey", borderRadius:"5px"},
    mailInputs: { marginBottom:"20px", width: "100%",padding:"8px",border:"1px solid grey", borderRadius:"5px"},
  };

  return (
    <div style={styles.container}>
      <ToastContainer />
      <h2 style={styles.heading}>Assessment Details</h2>
      
      <div style={{display:"flex",justifyContent:"space-between",}}>
      <h3 style={styles.subHeading}>
        No Of Registrations : {filteredData.length}
      </h3>
        <p><h3>Total Male Candidates: {maleCount}</h3></p>
        <p><h3>Total Female Candidates: {femaleCount}</h3></p>
        <div style={styles.filtersContainer}>
        <Button type="primary" onClick={copyRegistrationUrl}>
          {copyBtnText}
        </Button>
      </div>
      </div>
      <div className="searchkaro">
      
      <Select
            showSearch
            placeholder="Search College ID"
            optionFilterProp="label"
            onChange={onChange}
            // onSearch={onSearch}
            options={registrationData.map((option)=>{
              return ({value:option.college_Id , label:option.college_Id})
            })}
          />
     
        {/* <input style={styles.inputs} type="text" placeholder="Student Id" onChange={handleStudentId}/> */}
        <Button type="primary"  style={styles.buttonsContainer} onClick={resetAttempt}>Reset Test</Button>
      </div>
      <Select
        placeholder="Filter by Department"
        value={deptFilter}
        onChange={handleDeptFilterChange}
        style={styles.selectBox}
      >
        <Select.Option value="">All Departments</Select.Option>
        {uniqueDepts.map((dept) => (
          <Select.Option key={dept} value={dept}>
            {dept}
          </Select.Option>
        ))}
      </Select>
      <Table
        dataSource={filteredData}
        columns={[
          { title: "Name", dataIndex: "fullname", key: "fullname" },
          { title: "Gender", dataIndex: "gender", key: "gender" },
          { title: "Email", dataIndex: "email", key: "email" },
          { title: "College Name", dataIndex: "college_name", key: "college_name" },
          { title: "College ID", dataIndex: "college_Id", key: "college_Id" },
          { title: "Department", dataIndex: "dept", key: "dept" },
          { title: "Attempt", dataIndex: "login_state", key: "login_state" },
        ]}
        pagination={{ pageSize: 10 }}
        style={styles.table}
      />
      <div style={styles.buttonsContainer}>
        <Button type="primary" onClick={downloadExcel}>
          Download Filtered Data
        </Button>
        <Button
          type="primary"
          style={styles.button}
          onClick={() => setIsStudentModalVisible(true)}
        >
          Add Student Data
        </Button>
        {!isAssessmentStarted && (
          <Button
            type="primary"
            style={styles.button}
            onClick={() => setIsModalVisible(true)}
          >
            Start Assessment
          </Button>
        )}
      </div>
      <Modal
        title="Upload Assessment File"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="upload" type="primary" onClick={handleUpload}>
            {examPaperUploadText}
          </Button>,
          <Button key="start" type="primary" onClick={handleStartAssessment}>
            {startassessmentText}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Upload Exam Questions">
            <Input type="file" onChange={handleUploadFileChange} />
          </Form.Item>
        </Form>
        {startMailSending && <p>Sending Mails to ...{mailCount}/{filteredData.length}</p>}
      </Modal>
      <Modal
        title="Add Student Data"
        visible={isStudentModalVisible}
        onCancel={() => setIsStudentModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsStudentModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="upload" type="primary" onClick={handleStudentUpload}>
            {studentDataUploadText}
          </Button>,
        ]}
      >
        <>
        <input style={styles.mailInputs} type="text" placeholder="Enter Student Email" onChange={e=>setStudentRegMail(e.target.value)} /><br/>
        <Button type="primary" onClick={handleSendRegistrationLink}>{sendRegLinkText}</Button>
        </>
        <h3 style={{textAlign:"center"}}>Or</h3>
        <Form layout="vertical">
          <Form.Item label="Upload Student Data">
            <Input type="file" onChange={handleStudentFileChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Assessments;

