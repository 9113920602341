import React from 'react'
import { Link } from 'react-scroll'
import Headroom from 'react-headroom'
import { useLocation } from 'react-router-dom'

const Navbar = () => {

	const location = useLocation();
	const { pathname } = location;

	return (
		<Headroom>
			<nav className='navbar'>
				<div className='logoTitle'>
					<div className='d-flex'>
						<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-Dp15Sae3WZSZh8rdoKiQ68sZu8MjvFn3-Q&s" alt="logo" className='logo' height={'60px'}/>Vjthalearning Exam Tool
					</div>
				</div>
				{pathname === '/vjtha-drive/:assessmentId' && (
					<ul className='navList'>
						<li className='tags'><Link className='navLink' to="home" spy={true} smooth={true} offset={-100} duration={500}>Home</Link></li>
						<li className='tags'><Link className='navLink' to="about" spy={true} smooth={true} offset={-150} duration={500}>About</Link></li>
						<li className='tags'><Link className='navLink' to="services" spy={true} smooth={true} offset={-100} duration={500}>Our Services</Link></li>
						<li className='tags'><Link className='navLink' to="contact" spy={true} smooth={true} offset={-10} duration={500}>Contact</Link></li>
					</ul>
				)}
			</nav>
		</Headroom>
	)
}

export default Navbar