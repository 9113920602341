import React, { useEffect, useState } from "react";
import "../../Styles/Portalpage.css";
import "../../App.css";
// import Navbar from "../PageComponents/Navbar";
import { useNavigate } from "react-router-dom";
import PreventActionsComponent from "./PreventActionsComponent";
import Instructions from "./Instructions";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Modal, message, Result, Button } from "antd";
import Footer from "./PageComponents/Footer";
import Navbar from "./PageComponents/Navbar";
import ImageSlider from "../Carosuel/ImageSlider";

const Portal = () => {
  const [userData, setUserdata] = useState([]);
  const [infoVisibility, setInfoVisibility] = useState(false);
  const navigate = useNavigate();
  const { assessmentId, collegeId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alreadyAttempted, setAttempted] = useState(
    localStorage.getItem("isattempted")
  );
  const [auth, setAuth] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1740);
  const [timerColor, setTimerColor] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        handleTimeUp();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const shuffleQuestions = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
  };
  useEffect(() => {
    if (localStorage.getItem("isloggedin") === "true") setAuth(true);
    fetchQuestions();
  }, []);

  const fetchQuestions = () => {
    const userurl = `${process.env.REACT_APP_BASE_URL}/candidates/${assessmentId}/${collegeId}/getdetails`;
    axios
      .get(userurl)
      .then((res) => {
        setUserdata(res.data[0]);
      })
      .catch((err) => alert(err));
    const url = `${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/getquestions`;
    axios
      .get(url)
      .then((res) => {
        setQuestions(shuffleQuestions(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching questions:", err);
        setLoading(false);
      });
  };

  const handleOptionSelect = (optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[activeIndex].Answer = optionIndex.toString();
    setQuestions(updatedQuestions);
  };
  const forceSubmitTest = (reason) => {
    // const data = {
    //   name : userData.fullname,
    //   mail : userData.email
    // }
    // axios.post(`${process.env.REACT_APP_BASE_URL}/admin/sendalertmail`,data)
    generateReport(reason);
    setAttempted(true);
    localStorage.setItem("isattempted", "true");
    navigate("/submittest");
  };

  const handleSubmitTest = () => {
    Modal.confirm({
      title: "Confirm Submission",
      content: "Are you sure you want to submit the test?",
      onOk: async () => {
        generateReport("Test Submitted Succesfully");
        // const url = `${process.env.REACT_APP_BASE_URL}/admin/sendsuccessfulsubmissionmail`
        // const data = {
        //   name : userData.fullname,
        //   mail : userData.email
        // }
        // await axios.post(url,data)
        setAttempted(true);
        localStorage.setItem("isattempted", "true");
        navigate("/vjtha-drive/VjthaASXXXX");
      },
      onCancel: () => {},
    });
  };

  const generateReport = (reason) => {
    const attempted = questions.filter((item) => item.Answer !== null).length;
    const unattempted = questions.length - attempted;
    let correct = 0;
    let incorrect = 0;

    questions.forEach((item) => {
      if (item.Answer !== null && item.Answer === item.Solution) {
        correct++;
      } else if (item.Answer !== null && item.Answer !== item.Solution) {
        incorrect++;
      }
    });

    // const candidateData = JSON.parse(localStorage.getItem("candidateData"));
    const reports = {
      name: userData.fullname,
      gender: userData.gender,
      collegeId: userData.college_Id,
      collegeName: userData.college_name,
      score: correct,
      attempted: attempted,
      unattempted: unattempted,
      correct: correct,
      incorrect: incorrect,
      email: userData.email,
      phoneNumber: userData.phone,
      reason: reason,
    };

    localStorage.setItem("reports", JSON.stringify(reports));
    const url = `${process.env.REACT_APP_BASE_URL}/candidates/${assessmentId}/results`;
    axios
      .post(url, reports)
      .then((res) => {
        console.log("Test submitted successfully", reports);
        message.success("Test submitted successfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Multiple Submissions are not Allowed");
      });
    localStorage.setItem("isloggedin", "false");
    console.log(`Attempted Questions: ${attempted}`);
    console.log(`Unattempted Questions: ${unattempted}`);
    console.log(`Correct Answers: ${correct}`);
    console.log(`Incorrect Answers: ${incorrect}`);
  };

  const handleInfoVisibility = () => {
    setInfoVisibility(true);
  };

  const handleCloseInfo = () => {
    setInfoVisibility(false);
  };

  const handleResetOption = () => {
    const updatedData = [...questions];
    updatedData[activeIndex].Answer = null;
    setQuestions(updatedData);
  };

  const handlePrevious = (index) => {
    setActiveIndex(index > 0 ? index - 1 : index);
  };

  const handleNext = (index) => {
    setActiveIndex(index < questions.length - 1 ? index + 1 : 0);
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleTimeUp = () => {
    forceSubmitTest("Submitted Due to Timeup");
    setTimerColor("red");
  };

  if (alreadyAttempted) {
    return (
      <Result status="warning" title="You have already submitted the test" />
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  // if(!auth){
  //   return <h3>UnAuthorized Access</h3>
  // }
  return (
    <PreventActionsComponent finishTest={(reason)=>forceSubmitTest(reason)}>
    <>
      <Navbar />
      {/* <div className="portal-logo">
        <img src={logimg} alt="vts-logo" />
        <span >Vjthalearning</span>
      </div> */}
      <div>
        <button onClick={handleInfoVisibility} className="info-btn">
          i
        </button>
        {infoVisibility && (
          <Instructions className="slideRight" closeInfo={handleCloseInfo} />
        )}
        <div className={infoVisibility ? "portal-ctn-blur" : "portal-ctn"}>
          <div>
            <div className="qn-wrapper">
              <div>
                <h3 className="qn-here">Question No: {activeIndex + 1}</h3>
                <hr
                  
                  className="question-line"
                />
              </div>
              <div className="card">
                <div className="qn-card">
                  <div className="qn-container">
                    {questions[activeIndex].Image && (
                      <div className="qn-img">
                        <img
                          src={questions[activeIndex].Image}
                          alt="Question-img"
                        />
                      </div>
                    )}
                    {questions[activeIndex].Questions && (
                      <div className="qn-content">
                        {questions[activeIndex].Questions}
                      </div>
                    )}
                  </div>
                </div>
                <div className="options-card">
                  <h3>Options :</h3>
                  {questions[activeIndex].Opts &&
                    Object.keys(JSON.parse(questions[activeIndex].Opts)).map(
                      (optionKey) => (
                        <button
                          key={optionKey}
                          className={
                            questions[activeIndex].Answer === optionKey
                              ? "active-option"
                              : "default-option"
                          }
                          onClick={() => handleOptionSelect(optionKey)}
                        >
                          {JSON.parse(questions[activeIndex].Opts)[optionKey]}
                        </button>
                      )
                    )}
                </div>
              </div>
            </div>
            {/* <ImageSlider/> */}
            <div className="navigation-button-card">
              <button
                disabled={activeIndex === 0}
                onClick={() => handlePrevious(activeIndex)}
              >
                Previous
              </button>
              <button
                disabled={activeIndex === questions.length - 1}
                onClick={() => handleNext(activeIndex)}
              >
                Save & Next
              </button>
              <button onClick={handleResetOption}>Reset Option</button>
            </div>
          </div>
          <div className="sidebar">
            <h3
              style={{
                textAlign: "center",
                color: "red",
                padding: "20px",
                // margin: "15px auto",
              }}
            >
              Time Left:{" "}
              <span style={{ color: timerColor }}>{formatTime(timeLeft)}</span>
            </h3>
            <div className="btn-section">
              {questions.map((item, index) => (
                <button
                  key={index}
                  className={
                    item.Answer !== null
                      ? "answered-btn"
                      : activeIndex === index
                      ? "active-btn"
                      : "default-btn"
                  }
                  onClick={() => handleClick(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            <button className="submit-button" onClick={handleSubmitTest}>
              Submit Test
            </button>
          </div>
        </div>

        <ImageSlider className="adds" />
      </div>
      <Footer />
    </>
   </PreventActionsComponent>
  );
};

export default Portal;
