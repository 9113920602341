import React from 'react'

const Footer = () => {
	return (
		<p className='footer'>
			© Copyright <b>Vjthalearning</b>. All Rights Reserved 2024<br/>
		</p>
	)
}

export default Footer