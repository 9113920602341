import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, Button, Row, Col } from 'antd';
import * as XLSX from 'xlsx';
import axios from 'axios';

const Reports = () => {
  const { assessmentId } = useParams();
  const [assessmentName, setAssessmentName] = useState('');
  const [minScore, setMinScore] = useState('');
  const [maxScore, setMaxScore] = useState('');
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [responseCount, setResponseCount] = useState(0);
  const [maleCount, setMaleCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [filteredMaleCount, setFilteredMaleCount] = useState(0);
  const [filteredFemaleCount, setFilteredFemaleCount] = useState(0);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/${assessmentId}/getreports`);
        const data = response.data;
        setReportData(data);
        setFilteredData(data);
        setResponseCount(data.length);
        setAssessmentName(data[0]?.assessmentName || 'Assessment');
        countGenders(data, setMaleCount, setFemaleCount);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    };

    fetchReportData();
  }, [assessmentId]);

  const countGenders = (data, setMaleCount, setFemaleCount) => {
    const maleCount = data.filter(item => item.gender === 'Male').length;
    const femaleCount = data.filter(item => item.gender === 'Female').length;
    setMaleCount(maleCount);
    setFemaleCount(femaleCount);
  };

  const handleScoreFilter = () => {
    const min = parseFloat(minScore);
    const max = parseFloat(maxScore);
    const filtered = reportData.filter(item => item.score >= min && item.score < max);
    setFilteredData(filtered);
    setResponseCount(filtered.length);
    countGenders(filtered, setFilteredMaleCount, setFilteredFemaleCount);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Filtered Data');
    XLSX.writeFile(workbook, 'filtered_data.xlsx');
  };

  const styles = {
    container: { padding: '20px', fontFamily: 'Arial, sans-serif' },
    heading: { marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' },
    filterContainer: { marginBottom: '20px' },
    filterInput: { marginBottom: '10px', width: '100%' },
    button: { marginTop: '0px', display: 'block' },
    table: { marginTop: '20px' },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Report for {assessmentName} ({responseCount} responses)</h2>
      <div>
        {/* <p>Total Male Candidates: {maleCount}</p>
        <p>Total Female Candidates: {femaleCount}</p> */}
        <p><h5>Male Candidates: {filteredMaleCount}</h5></p>
        <p><h5>Female Candidates: {filteredFemaleCount}</h5></p>
      </div>
      <div style={styles.filterContainer}>
        <Row gutter={16}>
          <Col span={8}>
            <Input
              placeholder="Minimum Score"
              value={minScore}
              onChange={(e) => setMinScore(e.target.value)}
              type="number"
              style={styles.filterInput}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Maximum Score"
              value={maxScore}
              onChange={(e) => setMaxScore(e.target.value)}
              type="number"
              style={styles.filterInput}
            />
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={handleScoreFilter} style={styles.button}>
              Filter
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={filteredData}
        columns={[
          { title: 'Name', dataIndex: 'name', key: 'name' },
          { title: 'Gender', dataIndex: 'gender', key: 'gender' },
          { title: 'College ID', dataIndex: 'collegeId', key: 'collegeId' },
          { title: 'College Name', dataIndex: 'collegeName', key: 'collegeName' },
          { title: 'Score', dataIndex: 'score', key: 'score' },
          { title: 'Total Attempted', dataIndex: 'attempted', key: 'attempted' },
          { title: 'Correct', dataIndex: 'correct', key: 'correct' },
          { title: 'Incorrect', dataIndex: 'incorrect', key: 'incorrect' },
          { title: 'Email', dataIndex: 'email', key: 'email' },
          { title: 'Reason', dataIndex: 'reason', key: 'reason' },
        ]}
        rowKey="id"
        style={styles.table}
      />
      <Button type="primary" onClick={downloadExcel} style={styles.button}>
        Download Reports
      </Button>
    </div>
  );
};

export default Reports;
