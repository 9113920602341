
import axios from "axios";
import logoimg from "../assets/images/vjtha_logo.png"
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const Login = () => {
  const [angle, setAngle] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [color, setColor] = useState("black");
  const [ringColor, setRingColor] = useState("#f00");
  const [isChecking, setIsChecking] = useState(false);
  const [userdata, setUserdata] = useState();
  const { assessmentId, collegeId, password } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleRightClick = (e) => {
    e.preventDefault();
  };
  document.addEventListener("contextmenu", handleRightClick);
  useEffect(() => {
    const data = {
      college_Id: collegeId,
      password: password,
    };
    const url = `${process.env.REACT_APP_BASE_URL}/candidates/${assessmentId}/login`;
    axios
      .post(url, data)
      .then((res) => {
        setUserdata(res.data.user);
        localStorage.setItem("candidateData", JSON.stringify(res.data.user));
        localStorage.setItem("isloggedin", "true");
        setLoading(false);
        // navigate(`/vts/${assessmentId}/instructions`)
      })
      .catch((err) => {
        console.log(err);
        toast.error("Connection failed! Check your Internet Connectivity",{position:"top-center"});
      });
  }, []);
  useEffect(() => {
    if (!isChecking) return;
    const interval = setInterval(() => {
      setAngle((prevAngle) => (prevAngle < 400 ? prevAngle + 1 : prevAngle));
    }, 27);
    return () => clearInterval(interval);
  }, [isChecking]);

  const checkSpeed = async () => {
    const imageUrl =
      "https://upload.wikimedia.org/wikipedia/commons/3/3a/Cat03.jpg";
    const fileSize = 110000;

    const startTime = new Date().getTime();
    try {
      await fetch(imageUrl, { cache: "no-cache" });
      const endTime = new Date().getTime();

      const duration = (endTime - startTime) / 1000;
      const speedBps = (fileSize * 8) / duration;
      const speedMbps = speedBps / (1024 * 1024);

      return speedMbps;
    } catch {
      return 0;
    }
  };
  const startCheckingSpeed = () => {
    setIsChecking(true);
    const duration = 10000;
    const interval = 1500;

    const checkInterval = setInterval(async () => {
      const speedMbps = await checkSpeed();
      setSpeed(speedMbps);
      setColor(speedMbps > 0 ? "green" : "red");
      setRingColor(speedMbps > 0 ? "green" : "red");
    }, interval);

    setTimeout(() => {
      clearInterval(checkInterval);
      setIsChecking(false);
    }, duration);
  };

  const handleRestart = () => {
    setAngle(0);
    startCheckingSpeed();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      college_Id: collegeId,
      password: password,
    };
    const url = `${process.env.REACT_APP_BASE_URL}/candidates/${assessmentId}/login`;
    axios
      .post(url, data)
      .then((res) => {
        localStorage.setItem("candidateData", JSON.stringify(res.data.user));
        localStorage.setItem("isloggedin", "true");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Connection failed! Check your Internet Connectivity")
      });
  };
  const handleFullScreen = (e) => {
	e.preventDefault()
    // openFullscreenPopup(
    //   //`${process.env.REACT_APP_FRONTEND_URL}/vts-drive/${assessmentId}/${collegeId}/instructions`
    //    `http://localhost:3000/vts-drive/${assessmentId}/${collegeId}/instructions`
    // );
    navigate(`/vjtha-drive/${assessmentId}/${collegeId}/instructions`)
  };

 
  const openFullscreenPopup = (url) => {
    const popupWindow = window.open(
      url,
      "popUpWindow1",
      "width=" + window.screen.width + ",height=" + window.screen.height
    );
  
    if (popupWindow) {
      popupWindow.onload = function () {
        const style = popupWindow.document.createElement("style");
        style.textContent = `
          .blur-background {
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
          }
        `;
        popupWindow.document.head.appendChild(style);
  
        const fullscreenBtn = popupWindow.document.createElement("button");
        fullscreenBtn.textContent = "Enable Full Screen";
        fullscreenBtn.style.position = "absolute";
        fullscreenBtn.style.bottom = "8%";
        fullscreenBtn.style.left = "43%";
        fullscreenBtn.style.padding = "15px 20px";
        fullscreenBtn.style.fontSize = "16px";
        fullscreenBtn.style.cursor = "pointer";
        fullscreenBtn.style.color = "white";
        fullscreenBtn.style.backgroundColor = "#048319";
        fullscreenBtn.style.border = "none";
        fullscreenBtn.style.borderRadius = "18px";
        fullscreenBtn.addEventListener("click", function () {
          popupWindow.document.documentElement.requestFullscreen();
          fullscreenBtn.style.display = "none";
        });
  
        popupWindow.document.body.classList.add("blur-background");
        popupWindow.document.body.appendChild(fullscreenBtn);
      };
    } else {
      alert("Popup blocked. Please allow popups for this site.");
    }
  };
  
  return (
    <div>
      <ToastContainer />
      <div className="watermark-vts">
      <img className='watermark' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-Dp15Sae3WZSZh8rdoKiQ68sZu8MjvFn3-Q&s" alt='Vjthalearning-logo' /><span style={{color:"#261648"}}>Vjthalearning</span>
      </div>
      <div className="loginPageCont">
        <div className="loginImgCont">
          <div className="sidebarTop">
            <div className="logoTitle">
              <div className="d-flex">
                <img
                  src={logoimg}
                  alt="logo"
                  className="logo"
                  height={"60px"}
                />
                Vjthalearning
              </div><br />
              <label className="logoName">Assessment Portal</label>
            </div>
          </div>
          <div className="sidebarMiddle">
            <h2>Better Talent Measurements</h2>
            <p>
              From Instinct to Insights : Make Powerful and Precise People
              Decisions
            </p>
          </div>
          <div className="sidebarBottom">
            © Copyright Vjthalearning. All Rights
            Reserved 2024
          </div>
        </div>
        <div className="loginCont">
          {/* <div className="checkUp">
            <div className="netSpeed">
              <p>Test your Internet Speed</p>
              <div className="circleCont">
                <div
                  className="outerCircle"
                  style={{
                    background: `conic-gradient(${ringColor} ${angle}deg, #fff 0deg)`,
                  }}
                ></div>
                <div className="innerCircle"></div>
                <div className="speedNum" style={{ color }}>
                  {!isChecking && color === "black" && (
                    <button onClick={startCheckingSpeed} className="goBtn">
                      Go
                    </button>
                  )}
                  {speed !== 0 && isChecking && (
                    <p>{`${speed.toFixed(2)} Mbps`}</p>
                  )}
                  {speed === 0 && isChecking && <p>0 Mbps</p>}
                  {color === "green" && !isChecking && (
                    <img
                      src={process.env.PUBLIC_URL + "/images/success.png"}
                      className="netStatusImg"
                      alt="success"
                    />
                  )}
                  {color === "red" && !isChecking && (
                    <button onClick={handleRestart} className="retryBtn">
                      <img
                        src={process.env.PUBLIC_URL + "/images/fail.png"}
                        className="netStatusImg"
                        alt="failed"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr width="1" size="350" /> */}
          <form className="loginCard">
            <p className="loginTitle">Please Confirm your Details</p>
            <hr />
            {loading ? (
              <h5>Loading ....</h5>
            ) : (
              <>
                <div className="logDetails">
                  <div className="details">
                    <label>Name:</label>
                    <p>{userdata.fullname}</p>
                  </div>
                  <div className="details">
                    <label>College Id:</label>
                    <p>{userdata.college_Id}</p>
                  </div>
                  <div className="details">
                    <label>Gender:</label>
                    <p>{userdata.gender}</p>
                  </div>
                  <div className="details">
                    <label>College Name:</label>
                    <p>{userdata.college_name}</p>
                  </div>
                </div>
                <input
                  type="submit"
                  onClick={handleFullScreen}
                  value="Start Exam"
                  
                />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

