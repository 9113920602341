
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom';
import logo from  "../assets/images/Vjthalearning.png"

export default function ExamInstructions() {
    const [enableFullscreen,setEnabledFullScreen] = useState(false)
    useEffect(()=>{
        if(localStorage.getItem("enableFullscreen") === "true")
            setEnabledFullScreen(true)
    })

    const navigate = useNavigate();
 
    const [targetTime, setTargetTime] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const {assessmentId,collegeId} = useParams()
    useEffect(() => {
      const startCountdown = () => {
        const now = new Date();
        const target = new Date();
        target.setFullYear(2024);
        target.setMonth(6); 
        target.setDate(7); 
        target.setHours(9);
        target.setMinutes(30);
        target.setSeconds(0);
        target.setMilliseconds(0);
  
        if (target < now) {
          target.setDate(target.getDate() + 1);
        }

        setTargetTime(target);
      };
  
      startCountdown();
    }, []);
  
    useEffect(() => {
      let timer;
      if (targetTime) {
        timer = setInterval(() => {
          const now = new Date();
          const difference = targetTime - now;
  
          if (difference <= 0) {
            clearInterval(timer);
            setTimeLeft('Start Exam');
          } else {
            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
          }
        }, 1000);
      }
      return () => clearInterval(timer);
    }, [targetTime]);
    function newPopup(url) {
        var popupWindow = window.open(url, 'popUpWindow1', 'resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no');
    
        if (popupWindow) {
          popupWindow.onload = function () {
            popupWindow.document.documentElement.requestFullscreen();
          };
        }
      }

  const handleClick = ()=>{
    navigate(`vjtha-drive/${assessmentId}/assessment`)
  }
 
    return (
        <div style={{width:"100vw"}} className='instruction-page'>
          <div className="instr-watermark">
            <img src={logo} alt="vts-watermark" />
          </div>
            <Navbar/>
            <div className="instruction">
             <u><h2>Instructions for Online Test</h2></u>
              <h4>Please read the Instructions carefully before starting the test.</h4>
             <ul>
               <li>Arrange for stable Internet connectivity.</li>
                <li>The test can be taken on Laptop, Desktop, or Mobile devices.</li>
                <li>Giving the examination on a Laptop or Desktop is recommended for the best experience.</li>
                <li>Ensure your device is fully charged. If possible, arrange a UPS/Inverter for uninterrupted power supply.</li>
                <li>Close all unnecessary browsers or tabs before starting the test to avoid distractions.</li>
                <li>Once the exam begins, do not switch to other applications or tabs. Doing so may trigger security alerts or lead to test termination.</li>
                <li>We recommend using updated web browsers such as Google Chrome, Mozilla Firefox, or Safari on your device.</li>
                <li>Do not use the browser's back button or the close button to exit or navigate through the exam interface, as it may cause you to lose progress.</li>
                <li>The timer for the test will be visible at the top of the screen, allowing you to monitor the remaining time until the exam ends.</li>
             </ul>

              <div className="writeexam">
                <u><h2>Answering the question</h2></u>
                <p>Answers will be saved automatically, or you can save them by clicking the <strong>Save & Next</strong> button.</p>
                <p>There are three buttons available for navigating through the questions:</p>
                <ul>
                  <li><strong>RESET</strong>: Use this button to deselect a previously selected answer.</li>
                  <li><strong>SAVE & NEXT</strong>: Save your selected answer and proceed to the next question.</li>
                  <li><strong>PREVIOUS</strong>: Navigate back to the previous question.</li>
                  <li>To jump to a specific question, you can click the question number on the right side of the screen.</li>
                </ul>
                <div className="answer-status">
                  <p><span className="circle green"></span>Answer Attempted</p>
                  <p><span className="circle blue"></span>Answer Not Attempted</p>
                </div>
              </div>
              
                <div className="navigate">
                  <ul>
                    <li>You are allowed to freely shuffle between questions during the test at your convenience.</li>
                    <li>There are 60 questions in total. Each correct response will earn you one mark. Unanswered questions will receive a zero, and there is no negative marking for incorrect answers.</li>
                    <li>Ensure all answers are completed before submitting the test. Once you click the <strong>Submit</strong> button, you will not be able to make any further changes or continue with the test.</li>
                  </ul>
                  <button onClick={handleClick} className={timeLeft === 'Start Exam' ? "examStartBtn" : "examStartBtn inactive"} disabled={timeLeft === 'Start Exam' ? false : true}>
                    {timeLeft}
                  </button>
                </div>
              </div>
            <Footer/>
        </div>
    )
}
