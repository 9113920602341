import "../../Styles/Portalpage.css";
import React from "react";

const Instructions = ({ closeInfo }) => {
  const handleCloseInfo = () => {
    closeInfo();
  };

  return (
    <div>
      <div className="info-card">
        <div className="container">
          <button onClick={handleCloseInfo} className="close-btn">
            X
          </button>
          <div className="instruction">
            <div className="writeexam">
              <u>
                <h2>Answering the question</h2>
              </u>
              <p>
                The answers will be saved automatically, or you can save them by
                clicking <strong>Save & Next</strong>.
              </p>
              <p>
                The following buttons will appear on each screen, and their
                functionality is described below:
              </p>
              <ul>
                <li>
                  <strong>RESET</strong>: Deselects a previously selected
                  answer.
                </li>
                <li>
                  <strong>SAVE & NEXT</strong>: Saves the selected answer and
                  moves to the next question.
                </li>
                <li>
                  <strong>PREVIOUS</strong>: Redirects you to the previous
                  question.
                </li>
                <li>
                  To navigate directly to a question, click on the question
                  number displayed on the right side of the screen.
                </li>
              </ul>
              <div className="answer-status">
                <p>
                  <button className="green"></button>Attempted
                </p>
                <p>
                  <button className="white"></button>Unattempted
                </p>
                <p>
                  <button className="blue"></button>Current
                </p>
              </div>
            </div>

            <div className="navigate">
              <u>
                <h2>Navigating through the Questions</h2>
              </u>
              <p>
                There will be a total of 60 questions in the exam. You are
                allowed to shuffle between questions at any point during the
                test.
              </p>
              <ul>
                <li>
                  The exam consists of 60 questions, and each correct response
                  will earn one mark.
                </li>
                <li>
                  Unanswered questions will receive zero marks. There are no
                  negative marks for incorrect responses.
                </li>
                <li>
                  Make sure to complete all questions before submitting the
                  exam. Once you click <strong>Submit</strong>, you will not be
                  allowed to continue or change your answers.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;


// import "../../Styles/Portalpage.css";
// import React from 'react'

// const Instructions = ({closeInfo}) => {
//     const handleCloseInfo=()=>{
//         closeInfo()
//     }
//   return (
//     <div>
//       <div className="info-card">
      
//             <div className="container">
//             <button onClick={handleCloseInfo} className="close-btn">
//               X
//             </button>
//               <div className="instruction">
//                 <div className="writeexam">
//                   <u>
//                     <h2>Answering the question</h2>
//                   </u>
//                   <p>
//                     The answers will be saved automatically or you can save it
//                     by clicking save&next.
//                   </p>
//                   <p>
//                     There will be three buttons on each screen, the use of
//                     buttons is given below:
//                   </p>
//                   <ul>
//                     <li>
//                       <strong>RESET</strong>: Can be used to deselect a selected
//                       answer.
//                     </li>
//                     <li>
//                       <strong>SAVE & NEXT</strong>: Save the selected answer and
//                       proceed to next question.
//                     </li>
//                     <li>
//                       <strong>PREVIOUS</strong>: It will redirect to the
//                       previous question.
//                     </li>
//                     <li>
//                       To go to a question, click on the question number on the
//                       right side of the screen.
//                     </li>
//                   </ul>
//                   <div className="answer-status">
//                     <p>
//                       <button className="green"></button>Attempted
//                     </p>
//                     <p>
//                       <button className="white"></button>Unattempted
//                     </p>
//                     <p>
//                       <button className="blue"></button> Current
//                     </p>
//                   </div>
//                 </div>

//                 <div className="navigate">
//                   <u>
//                     <h2>Navigating through the Sections</h2>
//                   </u>
//                   <b>
//                     <h2>There will be total of three Categories:</h2>
//                   </b>
//                   <table className="sections-table">
//                     <thead>
//                       <tr>
//                         <th>Category</th>
//                         <th>Total No.Of Questions</th>
//                         <th>Marks</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td>Arithmetic</td>
//                         <td>30</td>
//                         <td>30</td>
//                       </tr>
//                       <tr>
//                         <td>Logical</td>
//                         <td>15</td>
//                         <td>15</td>
//                       </tr>
//                       <tr>
//                         <td>Verbal</td>
//                         <td>15</td>
//                         <td>15</td>
//                       </tr>
//                       <tr className="total-marks-row">
//                         <td>
//                           <strong>Total</strong>
//                         </td>
//                         <td>
//                           <strong>60</strong>
//                         </td>
//                         <td>
//                           <strong>60</strong>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <ul>
//                     <li>
//                       The three categories will be jumbled throughout the whole
//                       section.
//                     </li>
//                     <li>
//                       You can shuffle between questions anytime during the time
//                       stipulated for the exam.
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//     </div>
//   )
// }

// export default Instructions
