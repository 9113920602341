import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Table, Form, Input, DatePicker } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../AdminpageStyle/admin.module.css";
import axios from "axios";

const AdminPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/getassessments`;
    axios
      .get(url)
      .then((res) => setAssessments(res.data))
      .catch((error) => {
        console.error("Error fetching assessments:", error);
        toast.error("Failed to fetch assessments");
      });
  }, []);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const url = `${process.env.REACT_APP_BASE_URL}/admin/assessments`;
        const data = {
          name: values.name,
          drivedate: values.date,
        };
        axios.post(url, data).then((res) => {
          toast.success("Assessment created Successfully", {
            position: "top-center",
          });

          setAssessments((prevAssessments) => [
            ...prevAssessments,
            { assessmentid: res.data.assessmentid, name: values.name },
          ]);

          setIsModalVisible(false);
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const copyRegistrationUrl = (id) => {
    const registrationUrl = `${process.env.REACT_APP_FRONTEND_URL}/vjtha-drive2025/${id}`;
    navigator.clipboard.writeText(registrationUrl);
    setAssessments((prevAssessments) =>
      prevAssessments.map((assessment) =>
        assessment.assessmentid === id
          ? { ...assessment, copyBtnText: "Copied" }
          : assessment
      )
    );

    setTimeout(() => {
      setAssessments((prevAssessments) =>
        prevAssessments.map((assessment) =>
          assessment.assessmentid === id
            ? { ...assessment, copyBtnText: undefined }
            : assessment
        )
      );
    }, 3000);
  };

  const handleAssessmentClick = (id) => {
    navigate(`/admin/assessmentdetails/${id}`);
  };

  const handleViewReportsClick = (id) => {
    navigate(`/admin/assessmentreports/${id}`);
  };

  return (
    <div className="admin-page">
      <ToastContainer />

      <Modal
        title="Create Assessment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Assessment Name"
            rules={[
              {
                required: true,
                message: "Please input the name of the assessment!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            rules={[
              {
                required: true,
                message: "Please select the date of the assessment!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>

      <div className="assessments-section">
        <h2>Created Assessments</h2>
        {assessments.length > 0 ? (
          <Table
            dataSource={assessments}
            columns={[
              {
                title: "Assessment Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Action",
                key: "action",
                render: (text, record) => (
                  <>
                    <Button
                      style={{ margin: "4px" }}
                      onClick={() => handleAssessmentClick(record.assessmentid)}
                    >
                      View Details
                    </Button>
                    <Button
                      style={{ margin: "4px" }}
                      onClick={() => handleViewReportsClick(record.assessmentid)}
                    >
                      View Reports
                    </Button>
                    <Button
                      style={{ margin: "4px" }}
                      type="primary"
                      onClick={() => copyRegistrationUrl(record.assessmentid)}
                    >
                      {record.copyBtnText
                        ? record.copyBtnText
                        : "Copy Registration url"}
                    </Button>
                  </>
                ),
              },
            ]}
          />
        ) : (
          <p>No Assessments created yet.</p>
        )}
      </div>

      <Button type="primary" onClick={showModal}>
        Create Assessment
      </Button>
    </div>
  );
};

export default AdminPage;
