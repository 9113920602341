import React from 'react'

const Footer = () => {
  return (
    <div style={{
      height: "10vh",
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.1)",
      background:"#000",
      color:"white"
    }}>
      <div>© Copyright Vjthalearning. All Rights Reserved 2024.</div>
      <div>Designed by Build My Web Infotech</div>
    </div>
  )
}

export default Footer
