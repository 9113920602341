import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ReportComponent from './ReportComponent';
import axios from 'axios';

export default function Container() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [reports,setReports] = useState([]);
  const navigate = useNavigate()
  const [reportsVisibility,setReportVisibility]  = useState(false)
  const handleStarClick = (index) => {
    setRating(index + 1);
  };
  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem("reports"))
    setReports(data)
  },[])
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
    
  };
  const viewReports = ()=>{
    setReportVisibility(true)
  }

  const isSubmitDisabled = rating === 0 || feedback.trim() === '';
  const handleFeedback = ()=>{
    const url = `${process.env.REACT_APP_BASE_URL}/admin/feedback`
    const data = {
      assessmentId : reports.assessmentId,
      name : reports.name,
      feedback : feedback,
      rating : rating
    }
    axios.post(url,data)
    .then(res=>{
      toast.success('Thanks for the Feedback and Wish you All The Best',{
        position:"top-center"
      })
    })
    
    
    setTimeout(()=>{
      localStorage.removeItem("candidateData")
    localStorage.removeItem("reports")
    navigate("/vjtha-drive/VjthaASXXXX")

    },3000)
  }
  return (
    <>
    <ToastContainer />
    <Navbar/>
      <div className="testsubmitted">
        <h3>Your test was submitted successfully</h3>
      </div>
      {/* <div className="navigation">
        <table className="section-table">
          <thead>
            <tr>
              <th>Total Questions</th>
              <th>No. Of Questions Attempted</th>
              <th>No. Of Questions Unattempted</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{parseInt(reports.attempted)+parseInt(reports.unattempted)}</td>
              <td>{reports.attempted}</td>
              <td>{reports.unattempted}</td>   
            </tr>
          </tbody>
        </table>
        <div className="reports-here">
          <button className='view-report-btn' onClick={viewReports}>View Reports</button>
          {reportsVisibility && (<ReportComponent userdata={reports} />)}
        </div>
        <h3>Best of Luck for your Results</h3>

        <div className="textarea-container">
          <textarea
            className="feedback-textarea"
            placeholder="Please leave your feedback here..."
            value={feedback}
            onChange={handleFeedbackChange}
            rows={6}
          ></textarea>
        </div>

        <div className="rating-container">
          <h4>Rate your Experience</h4>
          <div className="star-rating">
            {[...Array(5)].map((_, index) => (
              <i
                key={index}
                className={`fas fa-star ${index < rating ? 'active' : ''}`}
                aria-hidden="true"
                onClick={() => handleStarClick(index)}
              />
            ))}
          </div>
        </div>
          <div className="submit">
            <button type="submit" disabled={isSubmitDisabled} onClick={handleFeedback}>
              Submit
            </button>
          </div>
      </div> */}
    </>
  );
}